import React from 'react'
import {connect} from 'react-redux'
import {reduxForm} from 'redux-form';
import moment from 'moment';
import {
    adicionarPassageiros,
    buscarMapaVeiculoItinerario,
    selecionarAssentoMapaItinerarioIda,
    criarBloqueioPoltronasTemporario,
    liberarBloqueioPoltronasTemporario
} from '../../actions/vendasActions';
import MapaVeiculoItinerario from './MapaVeiculoItinerario';
import {getVendaPendente, saveVendaPendenteLocalStorage} from '../../seguranca/securityContext';
import {showErrorMsg} from '../../util/messagesUtil';


export class AbaSelecionarPoltrona extends React.Component {
    constructor(props) {
        super(props);
        this.montarMapaAssentos = this.montarMapaAssentos.bind(this);
        this.state = {
            itinerarioSelecionado: null,
            textoFiltro: null,
            fileiraUmIda: [],
            fileiraDoisIda: [],
            fileiraTresIda: [],
            fileiraQuatroIda: [],
            fileiraUmVolta: [],
            fileiraDoisVolta: [],
            fileiraTresVolta: [],
            fileiraQuatroVolta: [],
        };
    }

    componentDidMount() {
        this.montarMapaAssentos();
    }

    selecionarAssentoItinerarioIda(assento, fileira, sentido) {
        this.selecionarPoltrona(assento, sentido, fileira)
    }

    removerNaRevervaPendente(poltronaId) {
        const vendaPendente = getVendaPendente();
        if (vendaPendente) {
            let novasReservas = [];
            vendaPendente.reservas.map(reserva => {
                if (reserva.poltronaId !== poltronaId) {
                    novasReservas.push(reserva)
                }
            });
            vendaPendente.reservas = novasReservas;
            saveVendaPendenteLocalStorage(vendaPendente)
        }
    }

    marcarPoltrona(assento, sentido, fileira) {
    const {passageiros, itinerarioIda, bloqueios } = this.props.venda;
        if (assento.selecionado) {
            const pas = passageiros.filter(f => f.poltronaId !== assento.poltrona.id);
            this.props.adicionarPassageiros(pas);
            this.props.selecionarAssentoMapaItinerarioIda(assento, fileira);
            this.removerNaRevervaPendente(assento.poltrona.id)
            if (bloqueios && bloqueios.length > 0) {
                let removerBloqueios = [];
                if(itinerarioIda.integracao){
                    removerBloqueios = bloqueios.filter(it => it.poltronaId === assento.poltrona.id).map(it => it.uuid);
                }else{
                    removerBloqueios = bloqueios.filter(it => it.poltronaId === assento.poltrona.id).map(it => it.id);
                }

                this.props.liberarBloqueioPoltronasTemporario(removerBloqueios, itinerarioIda.integracao);
            }
        } else {
            const passageiro = {
                poltronaId: assento.poltrona.id,
                numeroPoltrona: assento.poltrona.numero,
                sentido: 'IDA',
                tipoEmissao: 'NORMAL',
                taxaEmbarque: itinerarioIda.taxaEmbarque,
                valor: itinerarioIda.preco,
                criancaColo: false,
                pedagio: itinerarioIda.pedagio,
                valorANTT: itinerarioIda.precoANTT,
                habilitadoValorAntt: itinerarioIda.empresaHabilitadoValorAntt,
                itinerarioId: itinerarioIda.id,
                ddi: '+55',
                siglaPais: 'BRA'
            };
            const novaLista = Object.assign([], passageiros);
            novaLista.push(passageiro);
            if (novaLista.length > 4) {
                showErrorMsg('Máximo de 4 reservas por pedido');
                return
            }
            let parametro = {}
            if(itinerarioIda.integracao){
                // eslint-disable-next-line no-unused-vars
                parametro = {
                    departureStation: itinerarioIda.idEstacaoOrigem,
                    arrivalStation: itinerarioIda.idEstacaoDestino,
                    departureTime:itinerarioIda.dataHoraPartida,
                    arrivalTime: itinerarioIda.dataHoraChegada,
                    marketingCarrier: itinerarioIda.codeEmpresa,
                    fareClass: itinerarioIda.fareClass,
                    numeroPoltrona: assento.poltrona.numero

                }
            }else{
                parametro = {
                    poltronaId: assento.poltrona.id,
                    trechoOrigemId: itinerarioIda.trechoOrigemId,
                    trechoDestinoId: itinerarioIda.trechoDestinoId,
                    empresaId: itinerarioIda.idEmpresa,
                    itinerarioId: itinerarioIda.idItinerario,
                };
            }

            this.props.criarBloqueioPoltronasTemporario(parametro, itinerarioIda.integracao).then((resp) => {
                if (resp.payload.status === 200) {
                    this.props.adicionarPassageiros(novaLista);
                    this.props.selecionarAssentoMapaItinerarioIda(assento, fileira, resp.payload.data.uuid);
                }
            });

        }
    }

    selecionarPoltrona(assento, sentido, fileira) {
        this.marcarPoltrona(assento, sentido, fileira)
    }

    montarMapaAssentos() {
        this.montarMapaAssentosIda();
    }

    montarMapaAssentosIda() {
        const {origem, destino} = this.props.venda;
        if (this.props.ida) {
            this.props.changeFieldValue('formReserva',
                'embarqueIda', this.props.ida.embarque);
            this.props.buscarMapaVeiculoItinerario(this.props.ida.idItinerario, origem.id, destino.id, true, this.props.ida.tipoVeiculo);
        }
    }

    render() {
        const {mapaVeiculoItinerarioIda, origem, destino, itinerarioIda, valorTotalPassagens, valorANTT} = this.props.venda;

        return (
            <div className='mt-4 col-md-12'>
                {mapaVeiculoItinerarioIda.length && <MapaVeiculoItinerario
                    key={itinerarioIda.idItinerario}
                    valorTotalPassagens={valorTotalPassagens}
                    valorANTT={valorANTT}
                    habilitadoValorAntt={itinerarioIda.empresaHabilitadoValorAntt}
                    selecionar={this.selecionarAssentoItinerarioIda.bind(this)}
                    mapaVeiculo={mapaVeiculoItinerarioIda}
                    idItinerario={itinerarioIda.idItinerario}
                    exibeBotoesRedirecionamento
                    sentido='IDA'
                    ehIntegracao={itinerarioIda.integracao}
                    trecho={`${origem.nomeComUf} -> ${destino.nomeComUf}`}
                    data={moment(itinerarioIda.dataHoraPartida).format('DD/MM/YY HH:mm')}
                    dataChegada={moment(itinerarioIda.dataHoraChegada).format('DD/MM/YY HH:mm')}/>}
            </div>
        )
    }
}


export const mapStateToProps = state => {
    return{
        venda: state.venda,

    }};


export default connect(mapStateToProps,
    {
        buscarMapaVeiculoItinerario,
        adicionarPassageiros,
        selecionarAssentoMapaItinerarioIda,
        criarBloqueioPoltronasTemporario,
        liberarBloqueioPoltronasTemporario
    })(reduxForm({
    form: 'formReserva',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(AbaSelecionarPoltrona))
