import React from 'react'
import {connect} from 'react-redux'
import {reduxForm} from 'redux-form';
import moment from 'moment';
import {
    adicionarPassageiros,
    buscarMapaVeiculoItinerario,
    selecionarAssentoMapaItinerarioRetorno,
    liberarBloqueioPoltronasTemporario,
    criarBloqueioPoltronasTemporario
} from '../../actions/vendasActions';
import MapaVeiculoItinerario from './MapaVeiculoItinerario';
import {getVendaPendente, saveVendaPendenteLocalStorage} from '../../seguranca/securityContext';

export class AbaSelecionarPoltronaRetorno extends React.Component {
    constructor(props) {
        super(props);
        this.montarMapaAssentos = this.montarMapaAssentos.bind(this)
        this.state = {
            itinerarioSelecionado: null,
            textoFiltro: null,
            fileiraUmIda: [],
            fileiraDoisIda: [],
            fileiraTresIda: [],
            fileiraQuatroIda: [],
            fileiraUmVolta: [],
            fileiraDoisVolta: [],
            fileiraTresVolta: [],
            fileiraQuatroVolta: [],
        };
    }

    componentDidMount() {
        this.montarMapaAssentos();
    }

    selecionarAssentoItinerarioRetorno(assento, fileira, sentido) {
        this.selecionarPoltrona(assento, sentido, fileira)
    }

    removerNaRevervaPendente(poltronaId) {
        const vendaPendente = getVendaPendente();
        if (vendaPendente) {
            let novasReservas = []
            vendaPendente.reservas.map(reserva => {
                if (reserva.poltronaId !== poltronaId) {
                    novasReservas.push(reserva)
                }
            })
            vendaPendente.reservas = novasReservas;
            saveVendaPendenteLocalStorage(vendaPendente)
        }
    }

    marcarPoltrona(assento, sentido, fileira) {
        const {
            venda: {passageiros, itinerarioRetorno},
            adicionarPassageiros,
            selecionarAssentoMapaItinerarioRetorno,
            bloqueios
        } = this.props
        if (assento.selecionado) {
            const pass = passageiros.filter(f => f.poltronaId !== assento.poltrona.id)
            adicionarPassageiros(pass)
            selecionarAssentoMapaItinerarioRetorno(assento, fileira);
            this.removerNaRevervaPendente(assento.poltrona.id)

            if (bloqueios && bloqueios.length > 0) {
                let removerBloqueios = [];
                if(itinerarioRetorno.integracao){
                    removerBloqueios = bloqueios.filter(it => it.poltronaId == assento.poltrona.id).map(it => it.uuid);
                }else{
                    removerBloqueios = bloqueios.filter(it => it.poltronaId == assento.poltrona.id).map(it => it.id);
                }

                this.props.liberarBloqueioPoltronasTemporario(removerBloqueios, itinerarioRetorno.integracao);
            }

        } else {
            const passageiro = {
                poltronaId: assento.poltrona.id,
                numeroPoltrona: assento.poltrona.numero,
                sentido: 'RETORNO',
                tipoEmissao: 'NORMAL',
                taxaEmbarque: itinerarioRetorno.taxaEmbarque,
                pedagio: itinerarioRetorno.pedagio,
                valor: itinerarioRetorno.preco,
                criancaColo: false,
                valorANTT: itinerarioRetorno.precoANTT,
                habilitadoValorAntt: itinerarioRetorno.empresaHabilitadoValorAntt,
                ddi: '+55',
                siglaPais: 'BRA'
            }
            const novaLista = Object.assign([], passageiros);
            novaLista.push(passageiro);

            const parametro = {
                poltronaId: assento.poltrona.id,
                trechoOrigemId: itinerarioRetorno.trechoOrigemId,
                trechoDestinoId: itinerarioRetorno.trechoDestinoId,
                empresaId: itinerarioRetorno.idEmpresa,
                itinerarioId: itinerarioRetorno.idItinerario,
            };
            this.props.criarBloqueioPoltronasTemporario(parametro).then((resp) => {
                if (resp.payload.status === 200) {
                    adicionarPassageiros(novaLista);
                    selecionarAssentoMapaItinerarioRetorno(assento, fileira);
                }
            });
        }
    }

    selecionarPoltrona(assento, sentido, fileira) {
        this.marcarPoltrona(assento, sentido, fileira)
    }

    montarMapaAssentos() {
        this.montarMapaAssentosIda();
    }

    montarMapaAssentosIda() {
        const {origem, destino} = this.props.venda
        if (this.props.ida) {
            this.props.changeFieldValue('formReserva',
                'embarqueIda', this.props.ida.embarque);
            this.props.buscarMapaVeiculoItinerario(this.props.ida.idItinerario, origem.id, destino.id, true, this.props.ida.tipoVeiculo);
        }
    }


    render() {
        const {mapaVeiculoItinerarioRetorno, origem, destino, itinerarioRetorno, valorTotalPassagens, valorANTT} = this.props.venda
        return (
            <div className='mt-4 col-md-12'>
                {mapaVeiculoItinerarioRetorno.length && <MapaVeiculoItinerario
                    key={itinerarioRetorno.idItinerario}
                    valorANTT={valorANTT}
                    habilitadoValorAntt={itinerarioRetorno.empresaHabilitadoValorAntt}
                    valorTotalPassagens={valorTotalPassagens}
                    selecionar={this.selecionarAssentoItinerarioRetorno.bind(this)}
                    mapaVeiculo={mapaVeiculoItinerarioRetorno}
                    idItinerario={itinerarioRetorno.idItinerario}
                    exibeBotoesRedirecionamento
                    sentido='RETORNO'
                    ehIntegracao={itinerarioRetorno.integracao}
                    trecho={`${destino.nomeComUf} -> ${origem.nomeComUf}`}
                    data={moment(itinerarioRetorno.dataHoraPartida).format('DD/MM/YY HH:mm')}
                    dataChegada={moment(itinerarioRetorno.dataHoraChegada).format('DD/MM/YY HH:mm')}/>}
            </div>
        )
    }
}


export const mapStateToProps = state => {
    return {
        venda: state.venda,

    }};


export default connect(mapStateToProps,
    {
        buscarMapaVeiculoItinerario,
        adicionarPassageiros,
        selecionarAssentoMapaItinerarioRetorno,
        liberarBloqueioPoltronasTemporario,
        criarBloqueioPoltronasTemporario
    })(reduxForm({
    form: 'formReserva',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(AbaSelecionarPoltronaRetorno))
