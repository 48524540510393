import React from 'react';
import { Component } from 'react';

export default class BeneficiosEGratuidades extends Component {

    render() {
        return (
            <>
                <div className="menu-accordion-heading">
                    <a className="menu-accordion-toggle" href="#item-compra-5">
                        Benefícios e gratuidades
                        <i className="fa fa-caret-down p-float-right"></i>
                    </a>
                </div>
                <div id="item-compra-5" className="menu-accordion-body"
                    style={{ overFlow: 'hidden', display: 'none' }}>
                    <div className="menu-accordion-inner">
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            Como comprar passagens com desconto?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>As viações só liberam informações e vendas de
                                passagens
                                com benefício
                                presencialmente, na rodoviária. É preciso apresentar
                                os
                                documentos de identidade e
                                comprovação do desconto ou gratuidade.</p>
                        </div>
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            Como reservar minha passagem com gratuidade?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>N{this.props.nomeEmpresaComPrefixo} só vendemos passagens com valor integral,
                                não é
                                possível reservar
                                passagens.</p><br />
                            <p>Para garantir passagens com gratuidade ou desconto, é
                                necessário ir ao balcão de
                                atendimento da empresa responsável pela viagem.</p>
                        </div>
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            Como comprar passagens online com desconto de estudantes
                            ou
                            ID Jovem?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>O benefício para <b>estudante</b> é válido para
                                deslocamento entre a escola e
                                residência, nos dias letivos.</p><br />
                            <p>Para conseguir o benefício é preciso do comprovante
                                de
                                residência e do atestado de
                                matrícula informando o curso frequentado, dias
                                letivos,
                                horários de aula e duração
                                do curso e então é só solicitar direto no balcão da
                                empresa que for utilizar.</p>
                            <br />
                            <p>Para o <b>ID Jovem</b>, o passageiro deve ter entre
                                15 a 29 anos, renda de até dois
                                salários-mínimos e ser inscrito no CadÚnico
                                (Cadastro Único para Programas Sociais
                                do Governo Federal).</p><br />
                            <p>É só se cadastrar no site ou aplicativo para
                                smartphones do ID Jovem.</p><br />
                            <p>São 2 vagas gratuitas, mas depois de esgotadas também
                                são reservadas mais 2 vagas com
                                50% de desconto no valor das passagens, as passagens
                                são apenas para ônibus
                                convencionais e em viagens entre estados
                                diferentes.</p>
                        </div>
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            Como comprar passagens com desconto para idosos?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>Para conseguir o benefício para idoso é preciso
                                apresentar direto no balcão da viação
                                que irá viajar o documento de identidade oficial e o
                                comprovante de renda,
                                preferencialmente a carteira do idoso, fornecido
                                pelas
                                Prefeituras Municipais.</p>
                            <br />
                            <p>Na falta da Carteira de Idoso, se faz necessário o
                                documento de identidade e
                                comprovante de renda inferior a 2
                                salários-mínimos.</p><br />
                            <p>O quanto antes conseguir reservar melhor. As viações
                                reservam 2 poltronas para viajar
                                com gratuidade e mais 2 poltronas com 50% do
                                valor.</p>
                        </div>
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            Como comprar passagens com desconto para pessoas com
                            deficiência?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>Para esse benefício, o passageiro deve ser inscrito
                                no
                                Programa Passe Livre e ir ao
                                balcão de atendimento da empresa de ônibus na
                                rodoviária
                                para solicitar o
                                benefício.</p><br />
                            <p>Tem direito ao passe livre pessoas com deficiência
                                seja
                                física, mental, auditiva,
                                visual ou múltipla, que apresentem carência e
                                comprovem
                                a baixa renda.</p><br />
                            <p>Os documentos exigidos são a credencial do Passe
                                Livre e
                                um documento de identidade
                                com foto. Basta ir direto ao balcão de atendimento
                                da
                                empresa de ônibus que irá
                                viajar e solicitar a passagem.</p>
                        </div>
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            Como conseguir um cupom de desconto?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>Para garantir cupons e saber dos nossos descontos
                                exclusivos, é só se cadastrar em
                                nosso <a href={`${this.props.url}/cadastro-promocao`} rel="noreferrer">site</a> e ficar atento aos e-mails que enviamos,
                                além
                                de nos seguir nas redes
                                sociais.</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}