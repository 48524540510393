import {
    dadosVexado,
    dadosBrasilPassagem,
    dadosAmarelinho,    
    dadosAdamantina,
    dadosBilheteRodoviario,
    dadosbusexpresso,
    dadosNmTurismo,
    dadosAguiaTurismo ,
    dadosAltransportes ,
    dadosCometinhaPassagens ,
    dadosCrispinPassagens ,
    dadosDaviTurismo ,
    dadosExpressoNacionalBus ,
    dadosFerreiraPassagens ,
    dadosGigiPassagens ,
    dadosHumaitaPassagens ,
    dadosJonasTur ,
    dadosMarinhoViagens ,
    dadosMundialTurismo ,
    dadosPassagensAqui ,
    dadosPegasusTpTur ,
    dadosPrimosTurismo ,
    dadosSDTour ,
    dadosTerraDoSolTurismo ,
    dadosViagensNacionais ,
    dadosTransamericaPassagens,
    dadosFFPassagens,
    dadosViagensGuma,
    dadosDSousaPassagens,
    dadosPegabus,    
    dadosTransbrasilPassagens,
    dadosVipBrasil,
    dadosPassagemVirtual,
    dadosAlphaTransporte,
    dadosMixTravel,
    dadosJackMultiVendas,
    dadosBrToursViagem,
    dadosNewtur,
    dadosNaturalViagens,
    dadosPoliturTransferConfig,
    dadosTurissulpassagens
} from './config'

let browserHistory;

export const setBrowserHistory = (history) => {
    browserHistory = history;
};
const ambienteKey = 'ambiente_';

const destinosMaisVendidosKey = 'destinosMaisVendidosKey_';

export const getBrowserHistory = () => browserHistory;

export const configuracaoEmpresa = () => {
    const mapa = new Map();
    mapa.set('localhost:3000', dadosTurissulpassagens);
    mapa.set('localhost:3001', dadosPegasusTpTur);
    mapa.set('localhost:3002', dadosAmarelinho);
    mapa.set('localhost:3003', dadosBrasilPassagem);
    mapa.set('localhost:3004', dadosPegabus);
    mapa.set('localhost:3005', dadosAdamantina);
    mapa.set('localhost:3006', dadosbusexpresso);
    mapa.set('localhost:3007', dadosbusexpresso);    
    mapa.set('localhost:3008', dadosTransbrasilPassagens);
    mapa.set('localhost:3009', dadosVipBrasil);
    mapa.set('localhost:3010', dadosPassagemVirtual);
    mapa.set('localhost:3011', dadosPoliturTransferConfig);
    mapa.set('vexado.com.br', dadosVexado);
    mapa.set('vexado.com', dadosVexado);
    mapa.set('passagensadamantina.com.br', dadosAdamantina);
    mapa.set('brasilpassagens.com.br', dadosBrasilPassagem);
    mapa.set('viacaoamarelinho.com.br', dadosAmarelinho);
    mapa.set('sandbox.viacaoamarelinho.com.br', dadosAmarelinho);
    mapa.set('viacaoamarelinho.com', dadosAmarelinho);
    mapa.set('amarelinholog.com.br', dadosAmarelinho);
    mapa.set('grupoamarelinho.com', dadosAmarelinho);
    mapa.set('giroexpresso.com.br', dadosbusexpresso);
    mapa.set('passagensgiroexpresso.com.br', dadosbusexpresso);
    mapa.set('passagensgiroturismo.com.br', dadosbusexpresso);
    mapa.set('sandbox.passagensadamantina.com.br', dadosAdamantina);
    mapa.set('sandbox.brasilpassagens.com.br', dadosBrasilPassagem);
    mapa.set('sandbox.giroexpresso.com.br', dadosbusexpresso);
    mapa.set('sandbox.passagensgiroexpresso.com.br', dadosbusexpresso);
    mapa.set('sandbox.passagensgiroturismo.com.br', dadosbusexpresso);
    mapa.set('sandbox.vexado.com', dadosVexado);
    mapa.set('sandbox.vexado.com.br', dadosVexado);
    mapa.set('busexpresso.com', dadosbusexpresso);
    mapa.set('bilheterodoviario.com.br', dadosBilheteRodoviario);
    mapa.set('lumirviagens.com.br', dadosBilheteRodoviario);
    mapa.set('bilheteja.com.br', dadosBilheteRodoviario);
    mapa.set('nmtur.com.br', dadosNmTurismo);
    mapa.set('aguiaturismo.online', dadosAguiaTurismo);
    mapa.set('altransportes.online', dadosAltransportes);
    mapa.set('cometinhapassagens.online', dadosCometinhaPassagens);
    mapa.set('crispimpassagens.online', dadosCrispinPassagens);
    mapa.set('daviturismo.online', dadosDaviTurismo);
    mapa.set('expressonacionalbus.online', dadosExpressoNacionalBus);
    mapa.set('ferreirapassagens.online', dadosFerreiraPassagens);
    mapa.set('gigipassagens.online', dadosGigiPassagens);
    mapa.set('humaitapassagens.online', dadosHumaitaPassagens);
    mapa.set('jonastur.online', dadosJonasTur);
    mapa.set('marinhoviagens.online', dadosMarinhoViagens);
    mapa.set('mundialturismo.online', dadosMundialTurismo);
    mapa.set('passagensaqui.online', dadosPassagensAqui);
    mapa.set('pegasustptur.online', dadosPegasusTpTur);
    mapa.set('primosturismo.online', dadosPrimosTurismo);
    mapa.set('sdtour.online', dadosSDTour);
    mapa.set('sdpassagens.com', dadosSDTour);
    mapa.set('terradosolturismo.online', dadosTerraDoSolTurismo);
    mapa.set('viagensnacionais.online', dadosViagensNacionais);
    mapa.set('transamericapassagens.online', dadosTransamericaPassagens);
    mapa.set('ffpassagens.com', dadosFFPassagens);
    mapa.set('viagensguma.online', dadosViagensGuma);
    mapa.set('dsousapassagens.online', dadosDSousaPassagens);
    mapa.set('pegabus.com.br', dadosPegabus);
    mapa.set('sandbox.pegabus.com.br', dadosPegabus);
    mapa.set('basilioviagens.com.br', dadosBrasilPassagem);
    mapa.set('sandbox.basilioviagens.com.br', dadosBrasilPassagem);
    mapa.set('transbrasilpassagens.com', dadosTransbrasilPassagens);
    mapa.set('sandbox.transbrasilpassagens.com', dadosTransbrasilPassagens);
    mapa.set('viacaovipbrasil.com', dadosVipBrasil);
    mapa.set('sandbox.viacaovipbrasil.com', dadosVipBrasil);
    mapa.set('passagemvirtual.com', dadosPassagemVirtual);
    mapa.set('alphatransporte.click', dadosAlphaTransporte);
    mapa.set('mixtravel.click', dadosMixTravel);
    mapa.set('jackmultivendas.click', dadosJackMultiVendas);
    mapa.set('brtoursviagem.click', dadosBrToursViagem);
    mapa.set('newtur.click', dadosNewtur);
    mapa.set('naturalviagens.com', dadosNaturalViagens);
    mapa.set('politurtransfer.com', dadosPoliturTransferConfig);
    mapa.set('turissulpassagens.com.br', dadosTurissulpassagens);
    mapa.set('sandbox.turissulpassagens.com.br', dadosTurissulpassagens);

    return mapa;
};

export const recuperarConfEmpresa = () => {
    const conf = configuracaoEmpresa().get(window.location.host);
    if (conf != null) {
        return conf;
    }
    return configuracaoEmpresa().get('localhost:3002')
};

export const salvarAmbiente = (ambiente) => {
    localStorage.setItem(ambienteKey + recuperarConfEmpresa().id, JSON.stringify(ambiente))
};
export const getAmbiente = () => JSON.parse(localStorage.getItem(ambienteKey + recuperarConfEmpresa().id)) ? JSON.parse(localStorage.getItem(ambienteKey + recuperarConfEmpresa().id)) : {};


export const isAmbienteProd = () => getAmbiente().ambiente === 'live';

export const isAmbienteDev = () => getAmbiente().ambiente === 'sandbox';


export const salvarDestinosMaisVendidos = (destinosMaisVendidos) => {
    localStorage.setItem(destinosMaisVendidosKey + recuperarConfEmpresa().id, JSON.stringify(destinosMaisVendidos))
};
export const getDestinosMaisVendidos = JSON.parse(localStorage.getItem(destinosMaisVendidosKey + recuperarConfEmpresa().id)) ? JSON.parse(localStorage.getItem(destinosMaisVendidosKey + recuperarConfEmpresa().id)) : [];
