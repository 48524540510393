import React, { Component } from 'react';
import iconeMala from '../estilo/comum/img/icone-mala.svg'
import iconDinheiro from '../estilo/comum/img/icone-dinheiro.png'

export default class TermosECondicoesTransportePolitur extends Component {

    render() {
        return (
            <div style={{ marginTop: '50px' }}>
                <div className="container" id="TermosCompra">
                    <div className="loginHeader">
                        <h1 id='secao-termos-condicoes'>TERMOS E CONDIÇÕES</h1>
                        <div className="divider"></div>                        
                    </div>
                    <div>
                        <p><strong>TRANSFER SEMITERRESTRE MORRO DE SÃO PAULO</strong></p>
                        
                        <p>Os presentes Termos e Condições (T&C) se aplicam aos transportes de passageiros realizados pela Politur Viagens e Turismo.</p>
                        
                        <p>Sobre a Politur. Somos uma agência de viagens motivada por ideais de oferecer novas soluções em turismo e transportes de passageiros baseados na qualidade, conforto e segurança.</p>
                        
                        <p><b>1. Regras do Transporte</b> <img src={iconeMala} width='24' height='24' alt='mala' style={{position: 'relative', top: '6px'}}/></p>
                        
                        <p>1.1. Cada passageiro terá direito a levar 30 (trinta) quilos de bagagem no bagageiro e 5 (cinco) quilos de volume no porta-embrulho. </p>

                        <p>1.2. Os horários indicados na passagem são estimados e poderão variar a depender do trânsito e condições climáticas. A Politur não se responsabiliza por perdas de voos ou compromissos em razão de atrasos nos horários indicados acima.</p>

                        <p>1.3. Você deverá apresentar-se no local de embarque 20 (vinte) minutos antes do horário previsto para embarque.</p>

                        <p>1.4. Você deverá apresentar, no momento do embarque, os mesmos documentos de identificação pessoal informados no momento da compra.</p>

                        <p>1.4.1. Documentos divergentes com os dados informados podem impedir o embarque dos passageiros.</p>

                        <p>1.5. Você deverá respeitar as regras de uso dos veículos e embarcações utilizadas para realizar o seu transporte ao destino contratado.</p>

                        <p>1.6. Menores de 16 anos precisarão de autorização judicial para viajar, exceto quando: a) estiver acompanhado de ascendente ou colateral maior, até o terceiro grau, comprovado documentalmente o parentesco; b) de pessoa maior, expressamente autorizada pelo pai, mãe ou responsável.</p>

                        <p>1.7. A Politur não se responsabiliza por itens sem supervisão deixados no interior dos veículos utilizados para a realização do transporte dos passageiros, bem como por quaisquer atos de terceiros.</p>
         
                        
                        <p><b>2. Política de Alterações e Cancelamento</b></p>

                        <p>2.1. Alterações, cancelamentos e reembolsos deverão ser solicitados através de nossa Central de Atendimento com até 48 horas úteis antes do embarque e estarão sujeitas às penalidades abaixo:</p>

                        <div className='tabelaTermoCondicoesTransporte'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>Antecedência</td>
                                        <td>Penalidade</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><b>≤ 7 dias </b></td>
                                        <td>20% do Preço.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b> &gt; 7 dias <br /> &lt; 48 horas </b>
                                        </td>
                                        <td>50% do Preço.</td>
                                    </tr>
                                    <tr>
                                        <td><b>≥ 48 horas</b></td>
                                        <td>100% do Preço.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <p>2.2. Em casos de alteração ou cancelamento, o pagamento de penalidades e diferença de tarifa poderá ser realizado através de cartão de crédito ou Pix em uma única parcela.</p>

                        <p>2.3. Alterações de nome em passagens não são permitidas em nenhum momento.</p>

                        <p>2.4. Bilhetes parcialmente utilizados não serão reembolsados.</p>


                        <p><b>3. Política de Reembolso</b> <img src={iconDinheiro} width='24' height='24' alt='mala' style={{position: 'relative', top: '6px'}}/></p>
                        
                        <p>3.1. O prazo para reembolso pode variar entre 30 e 90 dias, de acordo com a política de prazo adotada por parte da administradora do cartão de crédito utilizado na compra.</p>

                        <p>3.2. A forma de lançamento dos créditos na fatura do cartão utilizado no momento da compra pode variar de acordo com a administradora do seu cartão de crédito.</p>
                         
                        
                        <p><b>4. Tratamento dos seus Dados Pessoais</b></p>

                        <p>4.1. Para que a Politur possa prestar os serviços, precisará tratar alguns dos seus dados pessoais, que podem ser informados diretamente por você, coletados durante a utilização dos serviços, ou, ainda, compartilhados por um terceiro.</p>

                        <p>4.2. Os seus dados poderão ser compartilhados com terceiros para viabilizar a execução dos serviços.</p>

                        <p>4.3. Seus dados também poderão ser compartilhados com (i) parceiros comerciais com os quais mantemos relacionamento de colaboração; (ii) com autoridades públicas, quando necessário para responder a um processo judicial ou administrativo; ou ainda (iii) com agências de publicidade e empresas de marketing para o desenvolvimento de campanhas. </p>

                        <p>4.3.1. Nesses casos, os terceiros com os quais os dados forem compartilhados terão acesso restrito aos dados pessoais necessário para execução das suas atividades e estarão obrigados a garantir a segurança dos dados de acordo com as previsões legais.</p>

                        <p>4.4. Você possui os seguintes direitos no que diz respeito ao tratamento dos seus dados pessoais:

                            <br/>a) Acesso – o direito de ser informado e ter acesso aos seus dados pessoais sob o nosso tratamento;

                            <br/>b) Correção – o direito de solicitar a atualização ou alteração dos seus dados pessoais desatualizados, incompletos ou incorretos;

                            <br/>c) Portabilidade – o direito de requerer que os dados pessoais sob o nosso tratamento sejam transferidos a outro prestador de serviços indicado por você;

                            <br/>d) Eliminação – o direito de ter seus dados pessoais eliminados das nossas bases de dados, ressalvadas as hipóteses legais de armazenamento;

                            <br/>e) Anonimização ou bloqueio – o direito de solicitar que os dados pessoais excessivos ao tratamento sejam submetidos à anonimização ou que este tratamento excessivo seja interrompido por nós;

                            <br/>f) Revogação – o direito de revogar o seu consentimento para as finalidades de tratamento a ele atreladas;

                            <br/>g) Informação sobre as consequências da revogação – o direito de ser informado sobre os desdobramentos da sua relação conosco e execução de determinada finalidade de tratamento caso o seu consentimento seja revogado;

                            <br/>h) Oposição – o direito de você se opor ao tratamento de dados pessoais que esteja sendo realizado em desacordo com as determinações da LGPD.</p>


                        <p><b>5. Aceitação dos termos deste T&C</b></p>

                        <p>5.1. Os presentes termos e condições são considerados vinculantes para as Partes a partir da emissão da passagem em nome do passageiro, mesmo que o documento não contenha qualquer assinatura física ou eletrônica, ou quando for realizado o primeiro pagamento.</p>
                    </div>
                </div>
            </div>
        )
    }
}