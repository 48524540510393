import React, { Component } from 'react'
import 'moment/locale/pt-br'
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import moment from 'moment';
import Passagem from './Passagem';
import DataProxima from './DataProxima';
import { getVendaPendente, saveVendaPendenteLocalStorage } from '../../seguranca/securityContext';
import { buscarDestinosMaisVendidos } from '../../actions/vendasActions';
import imgMapSearch from '../../estilo/comum/img/imgMapSearch.png';
import CardsDestino from '../layout/DestinosMaisVendidos/CardsDestinos';
import LoadingAssinc from '../inicio/LoadingAssinc';
import CardsFiltro from '../layout/CardsFiltro';
import { recuperarConfEmpresa } from '../../util/applicationContext';

export class Passagens extends Component {
    exibirPassagens() {
        const { passagens, proximas, selecionarItinerario, seIda, origem, destino, idEmpresaIda } = this.props;
        if (passagens.length) {
            if(seIda) {
                return (passagens.map(passagem =>
                    <Passagem seIda={seIda} selecionarItinerario={selecionarItinerario} key={passagem.idItinerario}
                        origem={origem}
                        destino={destino}
                        passagem={passagem} />,
                )
                )
            }
            if(!seIda) {
                    return (passagens.filter(it => it.idEmpresa === idEmpresaIda).map(passagem =>
                        <Passagem seIda={seIda} selecionarItinerario={selecionarItinerario} key={passagem.idItinerario}
                            origem={origem}
                            destino={destino}
                            passagem={passagem} />,
                    )
                    )
            }
        }
        if (proximas && proximas.length) {
            return (
                <div id='containerTextInfo' className="container">
                    <div className="card-group mb-2">
                        <div id='textoInfoPassagem' className="card col-md-12 align-content-center align-items-center"
                            style={{ fontSize: 20, backgroundColor: '#fff3cd', color: '#856404' }}>
                            <img id='img-map' src={imgMapSearch} />
                            <span id='textoInfo' className='mb-2 mt-2'>Não temos passagens disponíveis para a data selecionada,
                                mas temos disponibilidade para as seguintes datas:</span>
                        </div>
                    </div>
                    {proximas.map(data => <DataProxima key={data} data={data} seIda={seIda} />)}
                    {recuperarConfEmpresa().pastaEstilo !== 'politurtransfer' && <CardsDestino/>}
                </div>)
        }
        if(this.props.loadingInteg && !this.props.loading) {
            return (
                <div>
                </div>
            )
        }
        return (<div id='containerTextInfo' className="container">
            <div className="card-group mb-2">
                <div id='textoInfoPassagem' className="card  col-md-12">
                    <img id='img-map' src={imgMapSearch} />
                    <span id='textoInfo' className='mb-2 mt-2'>Não temos passagem disponível no momento para a busca realizada. Refaça a busca alterando a
                        data ou as cidades de origem e destino ou confira agora os nossos destinos mais procurados logo abaixo!</span>
                </div>
            </div>
            {recuperarConfEmpresa().pastaEstilo !== 'politurtransfer' && <CardsDestino/>}
        </div>);
    }

    continuarPedidoPendente(passageiros) {
        this.props.adicionarPassageiros(passageiros)
        this.props.alterarAba(5)
    }

    descartarPedidoPendente() {
        saveVendaPendenteLocalStorage(null);
        this.props.alterarAba(1)
    }

    render() {
        const { passagens, trecho } = this.props;
        const venda = getVendaPendente();
        // eslint-disable-next-line no-undef
        if (venda && _.size(venda.reservas) > 0) {
            const passageiros = venda.reservas.map(reserva => reserva.passageiroDto)
            return (
                <div className="container mt-2">
                    <div className="card-group mb-2">
                        <div className="card col-md-12 align-content-center align-items-center"
                            style={{ fontSize: 20, backgroundColor: '#fff3cd', color: '#856404' }}>
                            <span className='mb-2 mt-2'>Identificamos que existe uma compra pendente. </span>
                            <span className='mb-2 mt-2'>Deseja reabrir esse pedido?</span>
                            <div className="modal-footer">
                                <button type="button"
                                    onClick={() => this.continuarPedidoPendente(passageiros)}
                                    className="btn btn-laranja btn-sistema">Sim</button>
                                <button type="button"
                                    onClick={() => this.descartarPedidoPendente()}
                                    className="btn btn-laranja btn-sistema">Não</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (passagens) {
            return (
                <div>
                    <div className="container">
                        <CardsFiltro/>
                        <div className="card-group mb-2 mt-4">
                            <div className='card col-md-12 itinerario-dia'>
                                <span className='mt-2' style={{
                                    fontSize: 20, fontWeight: 'bold',
                                }}>{trecho}</span>
                                <span className='mb-2 mt-2' style={{
                                    fontSize: 24,
                                }}>{moment(this.props.data).format('dddd, DD/MM/YYYY')}</span>
                            </div>
                        </div>
                        {this.exibirPassagens()}
                        <LoadingAssinc />
                    </div>
                </div>
            )
        }
        return '';
    }
}

export class exibirCardsDestinos extends Component {
    render() {
        return (
            { buscarDestinosMaisVendidos }
        )
    }
}
function mapStateToProps ({ loadingInteg, loading }) {
    return loadingInteg, loading
  }

export default connect(mapStateToProps)(Passagens)
