import React, { useState } from 'react';
import imagemCeluarSmartphone from '../../estilo/comum/img/celular-smartphone.png'

const CardsGuiasDicas = ({ site }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const buttonText = isExpanded ? 'Recolher' : 'Ver mais';

    const corpo = (isExpanded) => {
        if (site === 'politurtransfer') {
            return templatePolitur(isExpanded)
        }


        return (
            <>
                 <h1>Passagem de ônibus online:</h1>

                {site === 'amarelinho' &&
                    <p>
                        Não importa se você vai curtir as férias, reencontrar a família ou fazer negócios. Quando o assunto é viagem o mais importante é chegar com conforto e segurança ao destino. Por essa razão, nosso maior propósito é fazer de tudo para que a sua jornada seja inesquecível a bordo de um de nossos amarelinhos.
                    </p>
                }

                <p>
                    {!isExpanded ? '' : null}

                    Novo jeito de efetuar compra e vendas online de <strong>passagens rodoviárias</strong>, compras para você cliente que viaja e busca <strong>passagens mais baratas</strong> e empresas parceiras que fornecem os melhores serviços para facilitar a conexão entre pessoas e seus destinos. Nossas soluções vêm para contribuir e para alavancar a modernização do setor rodoviário no Brasil, trazendo <strong>tecnologia, segurança e inclusão</strong>. Comprar passagem de ônibus pela internet? É aqui!

                </p>
                <h2>Compre sua passagem de ônibus:</h2>

                <p>Em nossa plataforma, você pode comprar passagem de ônibus pela internet, através do <strong>site ou aplicativo</strong>, gratuito e disponível tanto para Android quanto iOS. Atuamos com <strong>várias empresas e destinos</strong> em todo o Brasil. Venha descobrir os <strong>melhores preços de passagens</strong> de Ônibus para todo o Brasil, a <strong>rodoviária online</strong> na palma de sua mão!</p>

                {isExpanded && (
                    <>
                        <p>É muito simples e em poucos cliques <strong>você compara e pesquisa preços</strong>, horários, classes de serviços e ainda <strong>garante sua passagem online</strong> com muita facilidade. E para oferecer ainda mais conforto e segurança na compra de sua passagem de ônibus, você pode efetuar sua compra com <strong>diferentes métodos de pagamento</strong>. Compre sua passagem de ônibus e pague com cartão de crédito (com possibilidade de <strong>parcelamento em até 12 vezes</strong>), débito ou pix através PagSeguro. É simples, rápido e seguro.</p>

                        <h2>Viagem de ônibus é aqui:</h2>

                        <p>Além de garantir sua viagem ônibus com muita segurança e facilidade, você também embarca com muito mais tranquilidade. O seu bilhete de passagem fica disponível para embarcar com a <strong>leitura do QR Code no seu celular</strong>! A viagem de ônibus nunca foi tão fácil!</p>

                        <p>Caso precisar de qualquer ajuda, estamos <strong>disponíveis com o nosso suporte</strong> de segunda a sexta das 9h às 18h (exceto feriados). Juntos vamos mais longe!</p>

                        <p>Quer, precisa ou ainda está planejando sua viagem de ônibus? Conte conosco, <strong>buscamos sempre melhor atender</strong>!</p>

                        <h2>Tenha uma ótima compra e uma <strong>excelente viagem</strong>!</h2>
                    </>
                )}

            </>
        )
    };
    
    const templatePolitur = (isExpanded) => {
        return (
            <>
                <h1>Bem-vindo à Politur – Conectando Você ao Paraíso </h1>
                <p>Descubra um novo jeito de comprar passagens rodoviárias e agendar serviços de traslados. Nossa plataforma é ideal para você, que busca passagens mais baratas ou precisa de serviços de transporte prático e eficiente. Trabalhamos com empresas parceiras que oferecem os melhores serviços para conectar você aos seus destinos com conforto e segurança. Nossas soluções contribuem para modernizar o setor rodoviário e de traslados no Brasil, trazendo mais tecnologia, segurança e inclusão. Comprar passagem de ônibus ou agendar seu traslado pela internet? Aqui é o lugar certo!
                </p>
                {!isExpanded ? '' : null}
                
                {isExpanded && (
                    <>
                        <h2>Por que escolher a Politur?</h2>
                        <ul>
                            <li><b>Horários flexíveis </b>e saídas diárias de Salvador, Aeroporto e Hotéis da cidade.</li>
                            <li><b>Frota moderna e confortável</b>, incluindo catamarãs exclusivos e ônibus equipados com ar-condicionado e banheiro. </li>
                            <li><b>Equipe receptiva dedicada</b>, garantindo suporte e cuidado em cada etapa da sua viagem.</li>
                        </ul>

                    
                        <p>Seja para relaxar nas praias de Morro de São Paulo, conhecer a tranquilidade da Gamboa ou explorar os encantos da Ilha de Tinharé, com a Politur, a sua experiência começa bem antes do destino final.
                        </p>
                        <h2>Nosso compromisso</h2>
                        <p>Transformar o seu trajeto em uma jornada tão inesquecível quanto o destino. Porque aqui, cada detalhe importa.</p>
                        <p><img style={{ cursor: 'pointer' }} title='celular smartphone'
                                                    src={imagemCeluarSmartphone} /> Reserve agora mesmo e viva o melhor da Bahia com a Politur!</p>
                    </>
                )}
            </>
        )
    }

    return (
        <main className="cards-dicas" id="cards-dicas">
            <article className='about-us' id='about-us'>
                <header className='text-about-us' id='text-about-us'>
                   {corpo(isExpanded)}
                    {isExpanded && <button id='recolherButton' onClick={toggleExpand}>{buttonText}</button>}
                    {!isExpanded && <button id='verMaisButton' onClick={toggleExpand}>{buttonText}</button>}
                </header>

            </article>

        </main>
    );
};

export default CardsGuiasDicas;
