import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { recuperarConfEmpresa } from '../../util/applicationContext';
import topTrechos from '../layout/TopTrechosPassagens/topTrechos';
import topTrechosVexadoEagencias from '../layout/TopTrechosPassagens/topTrechosVexadoEagencias';
import topTrechosPolitur from './TopTrechosPassagens/topTrechosPolitur';

export class CardsTopTrechos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupedTrechos: []
        };
    }

    verificaQualSite() {
        const configEmpresa = recuperarConfEmpresa();
        const eHagenciaOuVexado = configEmpresa.pastaEstilo === 'agencia' || configEmpresa.pastaEstilo === 'vexado' || configEmpresa.pastaEstilo === 'brasilpassagens';
        const eHamarelinho = configEmpresa.pastaEstilo === 'amarelinho';
        const ehPolitur = configEmpresa.pastaEstilo === 'politurtransfer';

        if (eHagenciaOuVexado) {
            return topTrechosVexadoEagencias;
        } else if (eHamarelinho) {
            return topTrechos;
        } else if (ehPolitur) {
            return topTrechosPolitur;
        }

        return [];
    }

    retornarGrupoDe5(valores) {
        const configEmpresa = recuperarConfEmpresa();
        const ehPolitur = configEmpresa.pastaEstilo === 'politurtransfer';
        let valorPadrao = 5;

        if (ehPolitur) valorPadrao = 3;

        const grupo = [];
        for (let i = 0; i < valores.length; i += valorPadrao) {
            const array = valores.slice(i, i + valorPadrao);
            grupo.push(array);
        }
        return grupo;
    }

    render() {
        const trechosParaExibir = this.verificaQualSite();
        const groupedTrechos = this.retornarGrupoDe5(trechosParaExibir);
        return (
            <div id='container-topTrechos1' className='container-toptrechos'>
                <div id='containerTituloTrechos' className='titulo-topTrechos'>
                    <span>
                        <b id='tituloTrechos'> Top Trechos {recuperarConfEmpresa().nome} </b>
                    </span>
                    <span id='subTituloTopTrechos'>
                        <b id='subTituloTrechos'> Os trechos mais procurados em nossa central {recuperarConfEmpresa().pastaEstilo === 'politurtransfer' ? '' : 'de passagens'}</b>
                    </span>
                </div>
                <div className='container-groupedTrechos'>
                    {groupedTrechos.map((group, groupIndex) => (
                        <div key={groupIndex} id={`containertopTrechos${groupIndex}`} className='containertopTrechos'>
                            {group.map((trecho, index) => {
                                const baseURL = recuperarConfEmpresa().urlBase;
                                const hrefLink = `${baseURL}/passagem-de-onibus/${trecho.nomeCidadeOrigemComUFNormalizado}/${trecho.nomeCidadeDestinoComUFNormalizado}`;
                                return (
                                    <div className='col-md-4 mb-4' key={index}>
                                        <div className='card-topTrechos'>
                                            <a href={hrefLink} id='topTrechosLink'>
                                                <div className={recuperarConfEmpresa().pastaEstilo === 'politurtransfer' ? 'cardTrechoPolitur' : 'cardTrecho'}>
                                                    <div className='container-origemDestino'>
                                                        <div className='icon-topOrigem'>
                                                            <span id='destinoIcons' className="fa fa-map-marker"></span>
                                                            <h3 className='trechoOrigem'>{trecho.nomeCidadeOrigemComUF}</h3>
                                                        </div>
                                                        <div className='icon-topDestino'>
                                                            <span id='arrowTrechos' className='fas fa-arrow-right'></span>
                                                            <h3 className='trechoDestino'>{trecho.nomeCidadeDestinoComUF}</h3>
                                                            <span id='trechoDestinoIcons' className="fas fa-map-pin"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default withRouter(CardsTopTrechos);
