import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { buscarPassagens, buscarPassagensDistribusion, limparPassagens, selecionarDataIda, selecionarDestino, selecionarOrigem } from '../../../actions/vendasActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { recuperarConfEmpresa } from '../../../util/applicationContext';
import destinosMaisVendidos from '../DestinosMaisVendidos/dadosCardsDestinos';

const frases = [
    'Reserve agora',
    'Top Destino',
    'Melhor rota',
    'Mais conforto',
    'Melhores ofertas',
    'Não perca',
    'Reserve agora',
    'Top Destino',
    'Melhor rota',
    'Melhores ofertas',
    'Não perca',
    'Mais conforto'
];

const retornarFraseAleatoria = () => {
    return frases[Math.floor(Math.random() * frases.length)];
}

const item = (destino, index, buscar) => {
    const baseURL = recuperarConfEmpresa().urlBase;
    const hrefLink = `${baseURL}/passagem-de-onibus/${destino.nomeCidadeOrigemComUFNormalizado}/${destino.nomeCidadeDestinoComUFNormalizado}`;
    return (
        <a href={hrefLink} className="link-viagens">
            <div className="card-container" key={index}
                 onClick={() => buscar(destino)}>
                <img referrerPolicy="no-referrer"
                     src={destino.urlImagem}
                     className="img-cards" alt={`Viagem de ônibus para ${destino.nomeCidadeDestino}`}
                     onError={(e) => e.target.src = 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/imagem-paisagem-padrao.webp'}
                />

                <div id='precoDestino' className="card-title-destino">
                    <div id='titleH5' className="card-title-preco">A partir de:</div>
                    <div id='titleH5' className="card-title-valor">
                        {destino.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </div>
                </div>

                <div className='destino-subtitulo'>
                    {retornarFraseAleatoria()}
                </div>
                <div className="card-body-destinos">

                    <div className='texto-info'>
                        <div id='titleDestino' className="card-title-destino">
                            <span id='destinoIcons' className="fa fa-map-marker"></span>
                            {destino.nomeCidadeOrigemComUF}
                        </div>
                        <div id='titleDestino' className="card-title-destino">
                            <span id='destinoIcons' className="fas fa-map-pin"></span>
                            {destino.nomeCidadeDestino}
                        </div>
                    </div>
                </div>
            </div>
        </a>
    );
};

export class CardsDestino extends Component {
    constructor(props) {
        super(props)
        this.state = {
            card: { inicio: 0, fim: 16 },
            card2: { inicio: 16, fim: 32 },
            destinos: []
        }
    }

    async buscarPassagem(destinos) {
        const { empresaSelecionada } = this.props;
        this.props.limparPassagens()
        this.props.selecionarOrigem({
            id: destinos.idCidadeOrigem,
            uf: destinos.ufOrigem,
            nome: destinos.nomeCidadeOrigem,
            nomeComUf: destinos.nomeCidadeOrigem,
        });
        this.props.selecionarDestino({
            id: destinos.idCidadeDestino,
            uf: destinos.ufDestino,
            nome: destinos.nomeCidadeDestino,
            nomeComUf: destinos.nomeCidadeDestino,
        });

        const proximaData = new Date().toISOString().split('T')[0];
        let url = `/passagem-de-onibus/${destinos.nomeCidadeOrigemComUFNormalizado}/${destinos.nomeCidadeDestinoComUFNormalizado}/${proximaData}`;
        this.props.history.push(url);
        const idEmpresa = empresaSelecionada? empresaSelecionada.empresaId : null;
        await this.props.buscarPassagens(destinos.idCidadeOrigem, destinos.idCidadeDestino, destinos.proximaData, idEmpresa);

        if (recuperarConfEmpresa().distribusion) {
            this.props.buscarPassagensDistribusion(destinos.idCidadeOrigem, destinos.idCidadeDestino, proximaData, idEmpresa)
        }

    }

    componentDidMount() {        
        this.setState({ destinos: destinosMaisVendidos });        
    }

    montarItemDaBuscaPassagem = (ehCard2) => {
        let destinos = []
        if (!ehCard2) {
            destinos = this.state.destinos.slice(this.state.card.inicio, this.state.card.fim);
        }
        else {
            destinos = this.state.destinos.slice(this.state.card2.inicio, this.state.card2.fim);
        }
        if (destinos) {
            const itens = [];
            destinos.forEach((i, index) => {
                itens.push(item(i, index, this.buscarPassagem.bind(this)))
            });
            return itens;
        }
    }

    exibeCarousel(ehCard2) {
        let destinos = []
        if (!ehCard2) {
            destinos = this.state.destinos.slice(this.state.card.inicio, this.state.card.fim);
        }
        else {
            destinos = this.state.destinos.slice(this.state.card2.inicio, this.state.card2.fim);
        }
        return destinos && destinos.length > 0
    }

    render() {
        return (
            <section className="cards-destino" id="news" data-target="#cardDestinos">
                {this.state.destinos && this.state.destinos.length ?
                    <div id='secao-cards-destinos' className="container">
                        <span id='tituloDestinos'>Destinos + Procurados</span>

                        {this.exibeCarousel() && <AliceCarousel
                            items={this.montarItemDaBuscaPassagem()}
                            mouseTracking={true}
                            controlsStrategy="alternate"
                            disableDotsControls={true}
                            buttonsDisabled={true}
                            dotsDisabled={true}
                        />}

                        {this.exibeCarousel(true) && <AliceCarousel
                            items={this.montarItemDaBuscaPassagem(true)}
                            mouseTracking={true}
                            controlsStrategy="alternate"
                            disableDotsControls={true}
                            buttonsDisabled={true}
                            dotsDisabled={true}
                        />}
                    </div> : ''
                }
            </section>
        )
    }
}

export const mapStateToProps = state => (
    {
        destinos: state.venda.destinosMaisVendidos,
        empresaSelecionada: state.empresa.selecionada
    }
)

export default withRouter(connect(mapStateToProps, {
    buscarPassagens,
    buscarPassagensDistribusion,
    limparPassagens,
    selecionarOrigem,
    selecionarDestino,
    selecionarDataIda
})(CardsDestino))
