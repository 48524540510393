const topTrechosPolitur = [
    {
        idCidadeOrigem: 6303,
        nomeCidadeOrigemComUF: 'Salvador Aeroporto Luiz Eduardo Magalhães-BA',
        nomeCidadeOrigemComUFNormalizado: 'salvadoraeroportoluizeduardomagalhaes-ba',
        idCidadeDestino: 6305,
        nomeCidadeDestinoComUF: 'Morro de São Paulo-BA',
        nomeCidadeDestinoComUFNormalizado: 'morrodesaopaulo-ba',
        ordem: 1
    },
    {
        idCidadeOrigem: 6303,
        nomeCidadeOrigemComUF: 'Salvador Aeroporto Luiz Eduardo Magalhães-BA',
        nomeCidadeOrigemComUFNormalizado: 'salvadoraeroportoluizeduardomagalhaes-ba',
        idCidadeDestino: 7500,
        nomeCidadeDestinoComUF: 'Gamboa do Morro-BA',
        nomeCidadeDestinoComUFNormalizado: 'gamboadomorro-ba',
        ordem: 2
    },
    {
        idCidadeOrigem: 6335,
        nomeCidadeOrigemComUF: 'Salvador Terminal de São Joaquim',
        nomeCidadeOrigemComUFNormalizado: 'salvadorterminaldesaojoaquim-ba',
        idCidadeDestino: 6305,
        nomeCidadeDestinoComUF: 'Morro de São Paulo-BA',
        nomeCidadeDestinoComUFNormalizado: 'morrodesaopaulo-ba',
        ordem: 3
    },
    {
        idCidadeOrigem: 6335,
        nomeCidadeOrigemComUF: 'Salvador Terminal de São Joaquim',
        nomeCidadeOrigemComUFNormalizado: 'salvadorterminaldesaojoaquim-ba',
        idCidadeDestino: 7500,
        nomeCidadeDestinoComUF: 'Gamboa do Morro-BA',
        nomeCidadeDestinoComUFNormalizado: 'gamboadomorro-ba',
        ordem: 4
    },
    {
        idCidadeOrigem: 6335,
        nomeCidadeOrigemComUF: 'Salvador Terminal de São Joaquim',
        nomeCidadeOrigemComUFNormalizado: 'salvadorterminaldesaojoaquim-ba',
        idCidadeDestino: 6334,
        nomeCidadeDestinoComUF: 'Itaparica Terminal Bom Despacho-BA',
        nomeCidadeDestinoComUFNormalizado: 'itaparicaterminalbomdespacho-ba',
		ordem: 5
    },
    {
        idCidadeOrigem: 6305,
        nomeCidadeOrigemComUF: 'Morro de São Paulo-BA',
        nomeCidadeOrigemComUFNormalizado: 'morrodesaopaulo-ba',
        idCidadeDestino: 6303,
        nomeCidadeDestinoComUF: 'Salvador Aeroporto Luiz Eduardo Magalhães-BA',
        nomeCidadeDestinoComUFNormalizado: 'salvadoraeroportoluizeduardomagalhaes-ba',
		ordem: 6
    },
    {
        idCidadeOrigem: 6305,
        nomeCidadeOrigemComUF: 'Morro de São Paulo-BA',
        nomeCidadeOrigemComUFNormalizado: 'morrodesaopaulo-ba',
        idCidadeDestino: 6335,
        nomeCidadeDestinoComUF: 'Salvador Terminal de São Joaquim',
        nomeCidadeDestinoComUFNormalizado: 'salvadorterminaldesaojoaquim-ba',
		ordem: 7
    },
    {
        idCidadeOrigem: 7500,
        nomeCidadeOrigemComUF: 'Gamboa do Morro-BA',
        nomeCidadeOrigemComUFNormalizado: 'gamboadomorro-ba',
        idCidadeDestino: 6303,
        nomeCidadeDestinoComUF: 'Salvador Aeroporto Luiz Eduardo Magalhães-BA',
        nomeCidadeDestinoComUFNormalizado: 'salvadoraeroportoluizeduardomagalhaes-ba',
		ordem: 8
    },
    {
        idCidadeOrigem: 7500,
        nomeCidadeOrigemComUF: 'Gamboa do Morro-BA',
        nomeCidadeOrigemComUFNormalizado: 'gamboadomorro-ba',
        idCidadeDestino: 6335,
        nomeCidadeDestinoComUF: 'Salvador Terminal de São Joaquim',
        nomeCidadeDestinoComUFNormalizado: 'salvadorterminaldesaojoaquim-ba',
		ordem: 9
    },

    
];


export default topTrechosPolitur;