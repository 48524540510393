import React from 'react';
import { Component } from 'react';

export default class ServicoPolitur extends Component {

    render() {
        return (
            <>
                <div className="menu-accordion-heading">
                    <a className="menu-accordion-toggle" href="#item-compra-5">
                        Serviço Politur
                        <i className="fa fa-caret-down p-float-right"></i>
                    </a>
                </div>
                <div id="item-compra-5" className="menu-accordion-body"
                    style={{ overFlow: 'hidden', display: 'none' }}>
                    <div className="menu-accordion-inner">
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            Quanto tempo dura o trajeto de Salvador até Morro de São Paulo?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>O trajeto completo, combinando Catamarã e ônibus, leva cerca de 2h45 até 3h do Terminal São Joaquim até Morro de São Paulo. Se você sair do Aeroporto ou de um hotel, acrescente aproximadamente 1 hora para o translado terrestre.</p>
                        </div>
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            O passageiro pode parar antes de Morro de São Paulo?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>Sim! Temos uma parada no Atracadouro Bom Jardim, que fica em Valença, para quem deseja explorar destinos como a Ilha de Itaparica ou Valença.</p>
                        </div>
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            Como funciona o trajeto para a Ilha de Itaparica?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>Saímos do Terminal São Joaquim, em Salvador, com a travessia feita de Catamarã exclusivo para passageiros da Politur até o Terminal Bom Despacho, na Ilha de Itaparica. O trajeto dura em média 30 minutos, com assentos confortáveis e ar-condicionado.</p>                                                            
                        </div>
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            Posso comprar minha passagem no local?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>A Politur trabalha com agendamento preciso, garantindo a sua vaga e uma experiência organizada. Recomendamos que a reserva seja feita com antecedência por este canal ou nosso WhatsApp de atendimento: 71 99951-7900</p>
                        </div>
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            Quanto custa o trajeto de Salvador até Itaparica?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>O valor do Catamarã de Salvador (Terminal São Joaquim) até a Ilha de Itaparica é de R$ 50,00 por trecho (ida ou volta).</p>
                        </div>
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            O transfer funciona normalmente no fim de ano?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>Sim! Nossos serviços funcionam normalmente durante o fim de ano. Você pode aproveitar o Natal e o Réveillon sem preocupações, com a Politur garantindo o trajeto com toda segurança e conforto.</p>
                        </div>
                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            E se eu passar mal durante a travessia?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>O trecho feito de Catamarã é rápido, durando apenas 30 minutos, e é realizado com conforto e estabilidade. Para quem sente desconforto em percursos extensos, essa é uma excelente opção.</p>
                        </div>

                        <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                            Quais são os meios de transporte que a Politur oferece?
                            <i className="fa fa-caret-down p-float-right"></i>
                        </h3>
                        <div className="menu-accordion-inner-collapse">
                            <p>Trabalhamos com transfer semi-terrestre e terrestre:</p>
                            <ul>
                                <li><b>Semi-terrestre:</b> Travessia de Catamarã + ônibus até o destino.</li>
                                <li><b>Terrestre</b>: Trajeto rodoviário direto do Aeroporto de Salvador, com travessia de lancha rápida até Morro de São Paulo.</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}