import React, {Component} from 'react'
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {recuperarConfEmpresa} from '../../util/applicationContext';
import {buscarDestinosMaisVendidos} from '../../actions/vendasActions';
import {HashLink} from 'react-router-hash-link';
import topTrechos from './TopTrechosPassagens/topTrechos';
import topDestinos from './TopTrechosPassagens/topDestinos';

const navigateAndScroll = (id) => {
    return () => {
        window.history.pushState({}, '', '/top-trechos-passagens');

        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'auto' });
            }
        }, 0);
    };
};

export class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mostrarRodape: false
        };
    }

    alternarVisibilidadeRodape = () => {
        this.setState({
            mostrarRodape: !this.state.mostrarRodape
        });
    };

    arrow() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="arrow" width="12" height="12" fill="currentColor"
                 className={`bi bi-caret-up-fill ${this.state.mostrarRodape ? 'rotated' : ''}`} viewBox="0 0 16 16">
                <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
            </svg>
        );
    }

    montarItensDaListaViagensOnibusEDestino = () => {
        const destinos = this.props.destinos;

        let itens = [];

        if (destinos) {
            itens =
                destinos.map(valor => {
                    const urlDestino = `/passagem-de-onibus/${valor.nomeCidadeOrigemComUFNormalizado}/${valor.nomeCidadeDestinoComUFNormalizado}/${valor.proximaData}`;
                    const urlCidade = `/passagem-de-onibus/${valor.nomeCidadeDestinoComUFNormalizado}`;

                    const info = {
                        destino: valor.nomeCidadeDestino,
                        origem: valor.nomeCidadeOrigem,
                        urlDestino: urlDestino,
                        urlCidade: urlCidade
                    };

                    return info;
                });
        }

        return [...itens];
    }

    getLiCidades(infoCidades, isDestino = false) {
        const cidadesUnicas = infoCidades.filter((cidade, index) =>
            infoCidades.findIndex((c) => c.destino === cidade.destino) === index
        );
        return cidadesUnicas.map((cid, index) => (
            <li key={index} className='mt-2' to='#topo'>
                {isDestino ?
                    <HashLink to={cid.urlDestino}>{cid.origem} &#8594; {cid.destino}</HashLink> :
                    <HashLink to={cid.urlCidade}>{cid.destino}</HashLink>}
            </li>
        ));
    }

    handleDestinoClick = (urlDestino) => {
        setTimeout(() => {
            this.props.history.push(urlDestino);
        }, 0);
    }

    exibeViagensFixas() {
        return topTrechos.map((trecho, index) => {
            const urlDestino = `/passagem-de-onibus/${trecho.nomeCidadeOrigemComUFNormalizado}/${trecho.nomeCidadeDestinoComUFNormalizado}`;
            return (
                <li key={index} className='mt-2' to='#topo'>
                    <a href={urlDestino}>{trecho.nomeCidadeOrigemComUF} &#8594; {trecho.nomeCidadeDestinoComUF}</a>
                </li>
            );
        });
    }

    exibeDestinosFixos = () => {
        return topDestinos.map((trecho, index) => {
            const urlDestino = `/pagina-destinos/${trecho.nomeCidadeDestinoComUFNormalizado}`;
            return (
                <li key={index} className='mt-2' to='#topo' onClick={() => this.handleDestinoClick(urlDestino)}>
                    <a href={urlDestino}>{trecho.nomeCidadeDestinoComUF}</a>
                </li>
            );
        });
    }

    render() {
        const itens = this.montarItensDaListaViagensOnibusEDestino();
        const anoAtual = new Date().getFullYear();
        const {mostrarRodape} = this.state;

        return (
            <footer>
                <div className="container">
                <button
                        id="MaisInformacoes"
                        aria-label="Mais informacões"
                        className={`botaoMaisInformacoes ${mostrarRodape ? 'collapsed' : ''}`}
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded={mostrarRodape ? 'true' : 'false'}
                        aria-controls="collapseOne"
                        onClick={this.alternarVisibilidadeRodape}
                    >
                        Mais Informações {this.arrow()}
                    </button>
                    {mostrarRodape && <div className="row">
                        <div className="col-12 col-md sobre">
                            <div className='titulo-footer'>Viagens de ônibus</div>
                            {recuperarConfEmpresa().pastaEstilo === 'vexado' || recuperarConfEmpresa().pastaEstilo === 'brasilpassagens' || recuperarConfEmpresa().pastaEstilo === 'agencia' ?
                                <ul className="list-unstyled text-small">
                                    {this.exibeViagensFixas(itens, true).slice(0, 15)}
                                </ul>
                                :
                                <ul className="list-unstyled text-small">
                                    {this.getLiCidades(itens, true).slice(0, 15)}
                                </ul>
                            }
                        </div>
                        <div className="col-md-4">
                            <div className='titulo-footer'>Destinos</div>
                            {recuperarConfEmpresa().pastaEstilo === 'vexado' || recuperarConfEmpresa().pastaEstilo === 'brasilpassagens' || recuperarConfEmpresa().pastaEstilo === 'agencia' ?
                                <ul className="list-unstyled text-small">
                                    {this.exibeDestinosFixos(itens).slice(0, 15)}
                                </ul>
                                :
                                <ul className="list-unstyled text-small">
                                    {this.getLiCidades(itens).slice(0, 15)}
                                </ul>
                            }
                        </div>
                        <div className="col-md-4">
                            <div className='titulo-footer'>{recuperarConfEmpresa().nome}</div>
                            <ul className="list-unstyled text-small">
                                <li className='mt-2'>
                                    <HashLink className="mt-2" to='/quem-somos/#quem-somos'>
                                        Quem Somos
                                    </HashLink>
                                </li>
                                {recuperarConfEmpresa().id === 'vexado' ?
                                    <li className='mt-2 vexado'>
                                        <HashLink rel='noreferrer' to="/quero-uma-agencia#secao-quero-uma-agencia">
                                            Quero uma Agência
                                        </HashLink>
                                    </li> : ''}
                                {recuperarConfEmpresa().pastaEstilo !== 'politurtransfer' && <li className='mt-2'>
                                    <HashLink rel='noreferrer' to="/cards-destino#tituloDestinos">
                                        Destinos + Procurados
                                    </HashLink>
                                </li>}
                                {recuperarConfEmpresa().pastaEstilo !== 'politurtransfer' && <li className='mt-2'>
                                    <HashLink className="mt-2" to='/pesquisar-empresas#secao-trechos'>
                                        Pesquise Empresas
                                    </HashLink>
                                </li>}
                                <li className='mt-2'>         
                                    <a href='/atendimento/#secao-atendimento' rel="noopener noreferrer">
                                        Atendimento
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <HashLink className="mt-2" to='/termos-uso/#secao-termos'>
                                        Termos de uso
                                    </HashLink>
                                </li>
                                {recuperarConfEmpresa().pastaEstilo !== 'politurtransfer' ? <li className='mt-2'>
                                    <HashLink className="mt-2" to='/termos-compra/#secao-termos-compra'>
                                        Termos de Compra
                                    </HashLink>
                                </li>: <li className='mt-2'>
                                        <HashLink className='mt-2' to='/Termos-e-condicoes-transporte/#secao-termos-condicoes'>
                                            Termos e Condições de Transportes
                                        </HashLink>
                                    </li>}
                                <li className='mt-2'>
                                    <HashLink className="mt-2" to='/politica-privacidade/#secao-politica-privacidade'>
                                        Política de privacidade
                                    </HashLink>
                                </li>
                                <li className='mt-2'>
                                    <HashLink className="mt-2" to='/politica-cookies/#secao-politica-cookies'>
                                        Política de cookies
                                    </HashLink>
                                </li>
                                <li className='mt-2'>
                                    <a target="_blank" rel='noreferrer' href={this.props.empresa.facebook}>
                                        Facebook
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a target="_blank" rel='noreferrer' href={this.props.empresa.instagram}>
                                        Instagram
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a target="_blank" rel='noreferrer' href={this.props.empresa.tiktok}>
                                        Tiktok
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a target="_blank" rel='noreferrer' href={this.props.empresa.youtube}>
                                        YouTube
                                    </a>
                                </li>
                                <li className='mt-2 blog'>
                                    {recuperarConfEmpresa().pastaEstilo === 'brasilpassagens' ?
                                        <a href='https://www.brasilpassagensblog.com/' target="_blank" rel="noreferrer">
                                            Blog
                                        </a> :
                                        <a href='https://www.vexadoblog.com/' target="_blank" rel="noreferrer">
                                            Blog
                                        </a>}
                                </li>
                                <li className='mt-2'>
                                    {recuperarConfEmpresa().pastaEstilo === 'agencia' ?
                                        <a href='https://vexado.com.br/' target="_blank" rel="noreferrer">
                                            Vexado
                                        </a> : ''}
                                </li>
                                <li className='mt-2'>
                                    {recuperarConfEmpresa().pastaEstilo === 'agencia' ?
                                        <a href='https://brasilpassagens.com.br' target="_blank" rel="noreferrer">
                                            Brasil Passagens
                                        </a> : ''}
                                </li>
                                <li className='mt-2'>
                                    {(recuperarConfEmpresa().pastaEstilo === 'vexado' || recuperarConfEmpresa().pastaEstilo === 'brasilpassagens' || recuperarConfEmpresa().pastaEstilo === 'agencia') && (
                                        <HashLink className="mt-2" to='/top-trechos-passagens'
                                                  onClick={navigateAndScroll('tituloTrechos')}>
                                            Top Trechos - Passagens
                                        </HashLink>
                                    )}
                                </li>
                                {recuperarConfEmpresa().pastaEstilo !== 'politurtransfer' && <li className='mt-2'>
                                    <HashLink className="mt-2" to='/solicitar-gratuidade'
                                              onClick={navigateAndScroll('quem-somos')}>
                                        Solicitar Gratuidade
                                    </HashLink>
                                </li>}
                            </ul>
                        </div>
                    </div>}
                    <div className="row linha-Copyright">
                        {recuperarConfEmpresa().pastaEstilo === 'agencia' || recuperarConfEmpresa().pastaEstilo === 'turissulpassagens' || recuperarConfEmpresa().pastaEstilo === 'pegabus' || recuperarConfEmpresa().pastaEstilo === 'expressoadamantinanovo' ?
                            <div className="col-md-6 agencia-logo">
                                {recuperarConfEmpresa().logoRodape}
                                <div className='row'>
                                    <div className='col-md-12'><p
                                        className='denominacao'>{recuperarConfEmpresa().tipo}</p></div>
                                    <div className='col-md-12'><p
                                        className='nome-agencia'>{recuperarConfEmpresa().nome}</p></div>
                                </div>
                            </div>
                            :
                            <div className="col-md-3">
                                {recuperarConfEmpresa().logoRodape}
                            </div>
                        }
                        <div className={'col-md-' + ('8')}>
                            <span>© {anoAtual} Copyright - Todos os direitos reservados. {recuperarConfEmpresa().nome}.</span>
                        </div>
                        {recuperarConfEmpresa().pastaEstilo === 'agencia' ? '' :
                            <div className="col-md-3">

                            </div>
                        }
                    </div>
                </div>
            </footer>
        )
    }
}

export const mapStateToProps = (state) => (
    {
        empresa: recuperarConfEmpresa(),
        destinos: state.venda.destinosMaisVendidos
    });


export default withRouter(connect(mapStateToProps,
    {
        buscarDestinosMaisVendidos
    })(Footer))