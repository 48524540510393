import React from 'react';
import PagamentoPagSeguro from '../comum/PagamentoPagSeguro';
import safe from '../../estilo/comum/img/safe.svg';
import google from '../../estilo/comum/img/google.svg';
import ReactTooltip from 'react-tooltip';
import { recuperarMeioPagamento, recuperarOpcoesPagamento } from '../../actions/vendasActions';
import { alterarFormaPagamento } from '../../actions/alterarFormaPagamentoActions'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { change as changeFieldValue, formValueSelector, reduxForm } from 'redux-form';
import { showAlertMsg, showSuccessMsg } from '../../util/messagesUtil';
import { recuperarBandeiraCartao, validateCard } from '../../util/cartaoUtil';
import { mascaraNumero } from '../../util/arraysUtil';
import { getBrowserHistory } from '../../util/applicationContext';
import { paisesDDI } from '../../util/constantes';

export class AlterarFormaPagamento extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parcelas: [],
            bandeira: '',
            meioPagamento: 'PAG_SEGURO',
            formaPagamento: 'Cartão',
            numeroCartao: '',
            senderHash: '',
            valorTotalGeral: 0
        }
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.props.recuperarMeioPagamento().then(resp => this.setState({ meioPagamento: resp.payload }))
        this.carregarFormularioPagamento();
    }

    carregarFormularioPagamento() {
        this.setState(
            {
                valorTotalGeral: this.props.pedidoSelecionado.valor
            }
        )
    }

    montarParametros(values) {
        let comprador = null;
        const { username } = this.props;

        const formaPagamenro = {
            tipo: this.state.formaPagamento,
            base64Comprovante: '',
            numeroComprovante: '',
            valor: this.state.valorTotalGeral,
            quantidadeParcelas: values.parcelas ? values.parcelas.qtdParcelas : 1
        };

        if (this.state.formaPagamento !== 'PIX') {
            const pais = paisesDDI.find(it => it.id === values.ddiComprador.value)

            comprador = {
                cartao: {
                    numeroCartao: mascaraNumero(values.numeroCartaoCredito),
                    codigoSeguranca: values.codigoSegurancaCartaoCredito,
                    mesExpiracao: values.dataValidadeCartaoCredito.substr(0, 2),
                    anoExpiracao: '20'.concat(values.dataValidadeCartaoCredito.substr(3, 4)),
                    bandeira: this.state.bandeira
                },
                cpfComprador: values.cpfComprador,
                nomeComprador: values.nomeTitularCartaoCredito,
                email: username,
                ddiComprador: pais.ddi,
                siglaPais: pais.sigla,
                telefoneComprador: values.telefoneComprador,
                sanderHash: this.state.sanderHash,
                dataNascimentoComprador: values.dataNascimentoComprador,
                endereco: {
                    cep: values.cepComprador,
                    complemento: values.complementoEnderecoComprador,
                    numero: values.numeroEnderecoComprador
                },
            }
        } else {
            comprador = {
                email: username
            }
        }
        return {
            pedidoId: this.props.pedidoSelecionado.id,
            pontoVenda: 'SITE',
            formasPagamentoDto: [formaPagamenro],
            dadosCompradorDto: comprador
        };
    }

    submit(values) {
        const { formaPagamento } = this.state;
        if (!this.props.isAuthenticated) {
            getBrowserHistory().push('/login?venda');
        } else {
            this.props.alterarFormaPagamento(this.props.pedidoSelecionado.id, this.montarParametros(values)).then(() => {
                const url = `/recibo-pagamento?formaPagamento=${formaPagamento}`;
                showSuccessMsg('Forma de pagamento alterada com sucesso');
                getBrowserHistory().push(url);
            })
        }
    }

    handleOpcaoPagamento(formaPagamento) {
        this.setState({ formaPagamento })
    }

    parserValor(valor) {
        return valor.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
        })
    }

    buscarInformacoesPagamentoPagSeguro(numeroCartao, valorTotalGeral) {
        let parcelasPagamento = [];
        this.setState({ parcelas: [] });
        this.props.changeFieldValue('formReservaAlterarFormaPagamento', 'numeroCartaoCredito', numeroCartao);
        if (validateCard(numeroCartao) || this.ehAmbienteTeste(numeroCartao)) {
            const bandeiraCartao = recuperarBandeiraCartao(numeroCartao)
            this.setState({ bandeira: bandeiraCartao })
            this.props.recuperarOpcoesPagamento(valorTotalGeral, bandeiraCartao).then(resp => {
                if (resp) {
                    resp.payload.map(p => {
                        parcelasPagamento.push({
                            parcela: `${p.quantity} parcela(s) de ${this.parserValor(p.amount)} total de (${this.parserValor(p.totalAmount)}).`,
                            valorTotal: p.totalAmount,
                            qtdParcelas: p.quantity,
                            valorParcela: p.amount,
                            metodoPagamento: 'cartao',
                        })
                    })
                }
                this.preencherESelecionarPrimeiraOpcaoPagamento(parcelasPagamento, numeroCartao)
            }).catch(() => {
                showAlertMsg('Cartão inválido. Confira o numero do seu cartão de crédito!!')
            })
        }

    }

    preencherESelecionarPrimeiraOpcaoPagamento(parcelasPagamento, numeroCartao) {
        const parcela = {
            label: parcelasPagamento[0].parcela,
            value: parcelasPagamento[0].qtdParcelas,
            valorTotal: parcelasPagamento[0].valorTotal,
            qtdParcelas: parcelasPagamento[0].qtdParcelas,
            metodoPagamento: parcelasPagamento[0].metodoPagamento,
        };
        this.props.changeFieldValue('formReservaAlterarFormaPagamento', 'parcelas', parcela);
        this.setState({ parcelas: parcelasPagamento, numeroCartao })
    }

    buscarParcelas(e) {
        const numeroCartao = e.target.value.replaceAll(' ', '')
        const valorTotalGeral = Number(this.props.pedidoSelecionado.valor);
        if (validateCard(numeroCartao) || this.ehAmbienteTeste(numeroCartao)) {
            this.props.changeFieldValue('formReservaAlterarFormaPagamento', 'numeroCartaoCredito', numeroCartao);
            const bandeiraCartao = recuperarBandeiraCartao(numeroCartao);
            if (bandeiraCartao.length > 1) {
                this.buscarInformacoesPagamentoPagSeguro(numeroCartao, valorTotalGeral, bandeiraCartao, this.props);
            } else {
                showAlertMsg('Cartão inválido. Confira o numero do seu cartão de crédito!!')
            }
        }
    }

    render() {
        const valorCupomDesconto = this.props.pedidoSelecionado.reservas.map(it => Number(it.desconto)).reduce((sum, current) => sum + current, 0);
        const valorTotalTaxaServico = this.props.pedidoSelecionado.reservas.map(it => Number(it.taxaServico)).reduce((sum, current) => sum + current, 0);
        const valorTotalTaxaEmbarque = this.props.pedidoSelecionado.reservas.map(it => Number(it.taxaEmbarque)).reduce((sum, current) => sum + current, 0);
        const valorTotalPassagens = this.props.pedidoSelecionado.reservas.map(it => Number(it.valor)).reduce((sum, current) => sum + current, 0);
        const { parcelas } = this.state;
        const { handleSubmit } = this.props;
        return (
            <div className="">
                <form onSubmit={handleSubmit(this.submit)}>
                    <PagamentoPagSeguro parcelas={parcelas}
                        handleOpcaoPagamento={this.handleOpcaoPagamento.bind(this)}
                        formaPagamento={this.state.formaPagamento}
                        valorTotalGeral={this.state.valorTotalGeral}
                        buscarParcelas={this.buscarParcelas.bind(this)} />

                    <div className="card mt-2 container ddresumo">
                        <div className='titulo-item-pagamento'>Resumo da compra</div>
                        <div className="form-row mt-2 container-resumo">
                            <div className="col ddresumo-item">
                                <div className="mb-6">Valor das passagens</div>
                                <br />
                                <div className="valor-passagem-resumo">{valorTotalPassagens.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}</div>
                            </div>
                            <div className="col ddresumo-item">
                                <div className="mb-6">(+) Valor das taxas de embarque</div>
                                <div className="valor-passagem-resumo">{valorTotalTaxaEmbarque.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}</div>
                            </div>
                            <div className="col ddresumo-item">
                                <div data-tip data-for={'tooltip-taxa'} className='taxas-servico'>
                                    <div className="mb-6 taxa-servico">(+) Valor das taxas de serviço</div>
                                    <span className="align-self-center tooltip-info">
                                        <div className="tooltip-ut">
                                            <ReactTooltip className="tooltip-ut tool-taxa" id={'tooltip-taxa'}
                                                type="dark" effect="solid">
                                                <div className="row">Esta é uma taxa de conforto e comodidade de uma compra online,
                                                    onde com ela garantimos os melhores serviços e atendimentos.</div>
                                            </ReactTooltip>
                                        </div>
                                    </span>
                                    <div className="valor-passagem-resumo">{valorTotalTaxaServico.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}</div>
                                </div>
                            </div>
                            <div className="col ddresumo-item">
                                <div className="mb-6">(-) Valor do cupom de descontos</div>
                                <div className="valor-passagem-resumo">{valorCupomDesconto.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}</div>
                            </div>
                            <div className="col ddresumo-item last">
                                <div id='valorCompra' className="mb-6">Valor total da compra</div>
                                <div>
                                    <p className="valor-passagem-resumo final">
                                        {this.props.parcelas ?
                                            this.props.parcelas.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                            : this.state.valorTotalGeral.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-md-center modal-footer captcha'>
                        <div className='col col-lg-3'>
                            <img id='ssl' src={safe} />
                        </div>
                        <div className='col col-lg-3 google'>
                            <img id='google' src={google} />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <button id="btn-salvar" type="submit" style={{ width: 250 }}
                            className="btn btn-laranja btn-sistema">FINALIZAR PEDIDO
                        </button>
                        <button id="btn-salvar" type="submit" style={{ width: 250 }}
                            onClick={() => this.props.history.push('/minhas-reservas')}
                            className="btn btn-laranja btn-sistema">Retornar a pag anterior
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export function mapStateToProps(state) {
    return {
        pedidoSelecionado: state.pedido.pedidoSelecionado,
        parcelas: formValueSelector('formReservaAlterarFormaPagamento')(state, 'parcelas'),
        isAuthenticated: state.usuario.dadosAutenticacao.isAuthenticated,
        username: state.usuario.dadosAutenticacao.username
    }
}

export default withRouter(connect(mapStateToProps, {
    changeFieldValue,
    recuperarMeioPagamento,
    recuperarOpcoesPagamento,
    alterarFormaPagamento
})(reduxForm({ form: 'formReservaAlterarFormaPagamento' })(AlterarFormaPagamento)));