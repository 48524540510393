import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import {
    renderField,
    renderFieldCodigoSeguranca, renderFieldData,
    renderFieldNumeroCartao,
    renderFieldSelectParcelas, renderFieldTelefone,
    renderFieldText,
    renderFieldValidadeCartao,
    required, requiredCPF,
    minCartao,
    maxValueData,
    maxValueTelefone,
    requiredSelect,
    apenasLetra,
    maxTamanhoNome
} from '../../util/inputs';
import tipoPagamento from '../../estilo/comum/img/tipo-pagamento.png'
import {SLOW_REQUEST_CONFIG} from '../../util/loadingUtil';
import {URL} from '../../util/ambienteActions';
import axios from 'axios';
import { paisesDDI } from '../../util/constantes';
import Select from 'react-select';

export const validate = (values) => {
    const errors = {};
    if (!values.nome) {
      errors.nome = 'Campo obrigatório'
    }
    if (!values.email) {
      errors.email = 'Campo obrigatório'
  
    }
  
    return errors
  };

export class PagamentoPagSeguro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numeroCartao: null,
            parcelas: []
        }
    }

    componentDidMount() {
        this.setState({ parcelas: [] });
    }

    exibirComboDDI(event) {
        return (
            <div>
                <Select
                    {...event.input}                    
                    options={paisesDDI.map(elemento => ({
                      label: `${elemento.pais} ${elemento.ddi}`,
                      value: elemento.id
                    }))}
                    onChange={(value) => {                      
                      event.input.onChange(value);
                    }}                
                    onBlur={() => {}}
                />
                {event.meta.touched
                && (event.meta.error && <span style={{color: 'red'}}>{event.meta.error}</span>)}
          </div>
        )    
      }

    handleStyleFormaPagamento(formaPagamento) {
        return formaPagamento === this.props.formaPagamento ? '#a6bfe3' : '#f7f7f7'

    }

    pesquisarPorCep (event) {
        const CEP = event.target.value.replace(/\D/g, '')
        if (CEP.length === 8) {
          const url = `${URL}/cep/public/${CEP}`
          axios.get(url, SLOW_REQUEST_CONFIG)
        }
      }

    render() {
        const { buscarParcelas, handleOpcaoPagamento, formaPagamento } = this.props;
        return (
            <div className='container mt-2' style={{ padding: 0 }}>
                <form id="form-pagamento">
                    <div className="row">
                        <h2 className="titulo-item-pagamento m-2"
                            style={{
                                backgroundColor: this.handleStyleFormaPagamento('Cartão'),
                                cursor: 'pointer'
                            }}
                            onClick={() => handleOpcaoPagamento('Cartão')}>Pagar com cartão de crédito</h2>
                        <h2 className="titulo-item-pagamento m-2"
                            style={{ backgroundColor: this.handleStyleFormaPagamento('PIX'), cursor: 'pointer' }}
                            onClick={() => handleOpcaoPagamento('PIX')}>Pagar com pix</h2>
                    </div>
                    {formaPagamento === 'Cartão' ?
                        <div className="container mt-2 ddpagamento">
                            <img src={tipoPagamento} id='tipoPagamento' alt="Logotipos de meios de pagamento do PagSeguro"
                                title="Este site aceita pagamentos com as principais bandeiras e bancos, saldo em conta PagSeguro e boleto." />
                            <div className="row">
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                    <Field id="credit-card-number"
                                        label='Número do cartão'
                                        name='numeroCartaoCredito'
                                        buscarParcelas={buscarParcelas}
                                        maxLength="19"
                                        css="form-control"
                                        validate={[required, minCartao]}
                                        placeholder="____ ____ ____ ____"
                                        component={renderFieldNumeroCartao} />
                                </div>
                                <div className="col-12 col-sm-2 col-md-2 col-lg-2">
                                    <Field id="credit-card-date-expiration"
                                        name='dataValidadeCartaoCredito'
                                        label='Validade do cartão'
                                        maxLength="5"
                                        css="form-control"
                                        validate={[required]}
                                        placeholder="mm/aa"
                                        component={renderFieldValidadeCartao} />
                                </div>
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                    <label htmlFor="credit-card-number" className="active mb-2 mt-2">Código de segurança
                                        (CVV)</label>
                                    <Field id="credit-card-secure-code"
                                        name='codigoSegurancaCartaoCredito'
                                        formatacao={'###'}
                                        css="form-control"
                                        validate={[required]}
                                        placeholder="___"
                                        component={renderFieldCodigoSeguranca} />
                                </div>
                                <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <Field id="credit-card-holder-name"
                                        name='nomeTitularCartaoCredito'
                                        label='Nome do titular do cartão'
                                        placeholder='Nome igual está no cartão.'
                                        css="form-control"
                                        validate={[required, apenasLetra, maxTamanhoNome]}
                                        component={renderFieldText}
                                    />
                                </div>
                            </div>

                            <div className="row">                               
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                    <Field id="credit-card-document-number"
                                        name='cpfComprador'
                                        placeholder="___.___.___-__"
                                        css="form-control"
                                        label='CPF do titular'
                                        validate={[requiredCPF]}
                                        type='cpf'
                                        component={renderField} />

                                </div>

                                <div className="col-12 col-sm-2 col-md-2">
                                    <Field name='dataNascimentoComprador' type="text" component={renderFieldData}
                                        css="form-control" label="Data nascimento"
                                        validate={[required, maxValueData]}
                                    />
                                </div>
                                <div className="col-12 col-sm-3 col-md-3 mt-2">
                                    <label style={{marginBottom: '5px'}}>Código do País</label>
                                    <Field name='ddiComprador'
                                        validate={requiredSelect}
                                        component={this.exibirComboDDI.bind(this)}
                                        css="form-control col-md-2" />
                                </div>
                                {this.props.ddiComprador 
                                    && this.props.ddiComprador.value === 32 ? <div className="col-12 col-sm-3 col-md-3">
                                    <Field name='telefoneComprador' type="text" component={renderFieldTelefone}
                                        css="form-control" label="Telefone"
                                        validate={[required, maxValueTelefone]}
                                    />
                                </div> :<div className="col-12 col-sm-3 col-md-3">
                                    <Field name="telefoneComprador" type="text" max={15} component={renderField} 
                                        validate={[required]}
                                        css="form-control" label="Telefone"/>
                                    </div>}
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                    <Field id="credit-card-zip-code"
                                        name='cepComprador'
                                        label='CEP da fatura do cartão'
                                        component={renderField}
                                        validate={[required]}
                                        onChange={e => this.pesquisarPorCep(e)}
                                        type='cep'
                                        placeholder="_____-___"
                                        css="form-control" />

                                </div>
                                <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <Field name='complementoEnderecoComprador'
                                        label='Complemento(Casa, Apartamento e etc.)'
                                        component={renderField}
                                        validate={[required]}
                                        type='text'
                                        css="form-control"
                                        min="1"
                                        max="20" />
                                </div>
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                    <Field id="credit-card-zip-code"
                                        name='numeroEnderecoComprador'
                                        label='Número do complemento'
                                        component={renderField}
                                        validate={[required]}
                                        type='text'
                                        css="form-control" />
                                </div>
                            </div>
                            {this.props.parcelas && this.props.parcelas.length ?
                                <div className='row'>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                        <Field id="credit-card-zip-code"
                                            name='parcelas'
                                            options={this.props.parcelas}
                                            label='Em quantas parcelas?'
                                            placeholder='Selecione...'
                                            validate={requiredSelect}
                                            component={renderFieldSelectParcelas}
                                            css="form-control" />

                                    </div>
                                </div> : ''}
                        </div> : ''
                    }
                </form>
            </div>
        )
    }

}

export function mapStateToProps(state) {
    return {    
        ddiComprador: formValueSelector('formReserva')(state, 'ddiComprador')
    }
  }
  
  export default connect(mapStateToProps, {})(reduxForm({
    form: 'formReserva',
    validate
  })(PagamentoPagSeguro));